import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { NoBscProviderError } from '@binance-chain/bsc-connector';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { NoEthereumProviderError, UserRejectedRequestError as UserRejectedRequestErrorInjected, } from '@web3-react/injected-connector';
import { injected, } from '../connectors';
import { ACTION_CONST, connectorLocalStorageKey, ConnectorNames, MESSAGE } from '../constants';

function useAuth() {
  const dispatch = useDispatch();
  const { activate, deactivate } = useWeb3React();
  const connectorsByName = {
    [ConnectorNames.Injected]: injected,
    // [ConnectorNames.BSC]: bscConnector,
    // [ConnectorNames.WalletConnect]: walletConnect,
  };

  const login = useCallback((connectorID) => {
    const connector = connectorsByName[connectorID];
    if (connector) {
      try {
        activate(connector, async (error) => {
          window.localStorage.removeItem(connectorLocalStorageKey);
          if (error instanceof UnsupportedChainIdError) {
            dispatch({
              type: ACTION_CONST.ALERT_WARNING,
              message: MESSAGE.WRONG_NET_WORK
            })

          } else if (
            error instanceof NoEthereumProviderError ||
            error instanceof NoBscProviderError
          ) {
            dispatch({
              type: ACTION_CONST.ALERT_FAILS,
              message: 'No provider was found'
            })

          } else if (error instanceof UserRejectedRequestErrorInjected) {

            dispatch({
              type: ACTION_CONST.ALERT_FAILS,
              message: 'Please authorize to access your account'
            })
          } else {

            dispatch({
              type: ACTION_CONST.ALERT_FAILS,
              message: error.message
            })

            setTimeout(() => {
              window.location.reload()
            }, 2000)

          }
        });
      } catch (error) {
        console.log(error);
      }

    } else {
      dispatch({
        type: ACTION_CONST.ALERT_FAILS,
        message: 'The connector config is wrong'
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { login, logout: deactivate };
}

export default useAuth;
