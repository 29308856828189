import React from 'react';
import ConnectWalletModal from '../modals/ConnectWalletModal';
import WalletModal from '../modals/WalletModal';
import HelpModal from '../modals/HelpModal';
import { Link } from "react-router-dom";
import { ROUTES } from '../../constants';
import OHTTooltip from "../../component/Tooltip";
import { version } from '../../../package.json';

export default function Footer() {
  return (
    <div className="p-footer">
      <div className="container">
        <div className="row align-items-end">
          <div className="col-md-5 text-center text-md-start">
            <div><img src="/images/logo_w.png" height="40"/></div>
            <div className="mt-3">Copyright © {new Date().getFullYear()} ADAPAD Company. All rights reserved</div>
          </div>
          <div className="col-md-2 text-center text-md-start">v{version}</div>
          <div className="col-md-5 text-center text-md-end mt-3 mt-md-0">
            <div className="d-flex align-items-center justify-content-center justify-content-md-end" style={{ gap: '15px' }}>
              <a href="https://t.me/Adapadofficial" target="_blank">
                <OHTTooltip tooltip="ADAPAD Official">
                  <i className="fab fa-telegram"></i>
                </OHTTooltip>
              </a>
              <a href="https://twitter.com/ADAPadofficial" target="_blank">
                <OHTTooltip tooltip="ADAPAD Twitter">
                  <i className="fab fa-twitter"></i>
                </OHTTooltip>
              </a>
              <a href="https://medium.com/@ADAPad" target="_blank">
                <OHTTooltip tooltip="ADAPAD Medium">
                  <i className="fab fa-medium-m"></i>
                </OHTTooltip>
              </a>
              <a href="https://adapad.io/" target="_blank">
                <OHTTooltip tooltip="ADAPAD Website">
                  <i className="far fa-globe"></i>
                </OHTTooltip>
              </a>
            </div>
            <div className="mt-3">
              <Link target="_blank" to={ROUTES.PRIVACY_POLICY}>Privacy Policy </Link>
              <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
              <Link target="_blank" to={ROUTES.TERMS_OF_USE}>Terms of Use </Link>
            </div>
          </div>
        </div>
      </div>
      <ConnectWalletModal />
      <HelpModal />
      <WalletModal />
    </div>
  );
}