import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "web3modal";
import { ACTION_CONST, ROUTES } from "../../constants";
import { helpers } from "../../utils/helpers";
import exactMath from 'exact-math';
import { useActiveWeb3React } from "../../hook";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useKycStatus, useLatestBlockNumber, useStateAmount, useWeb3Utils } from "../../hook/useState";
import { getKYC } from "../../redux/services/kyc.api";

const Header = () => {
  const dispatch = useDispatch();
  const { account, library, chainId } = useActiveWeb3React()
  const showModalHelp = useSelector((state) => get(state, "utils.showModalHelp", false));
  const latestBlock = useLatestBlockNumber()
  const web3Utils = useWeb3Utils()
  const [adapadBalance, setADAPADBalance] = useState(0);
  const [enableKYC, setEnableKYC] = useState(false)
  const stakedAmount = useStateAmount()
  const kycStatus = useKycStatus()

  //set balance
  useEffect(() => {
    if (web3Utils && account) {
      //get bnb balance
      web3Utils.web3.eth.getBalance(account).then(balance => {
        dispatch({
          type: ACTION_CONST.GET_BNB_BALANCE,
          data: exactMath.div(balance, 10 ** 18)
        })
      }).catch(err => {
        console.log(err);
      })

      //get ADAPAD balance
      web3Utils.getTokenPadBalance().then(data => {
        dispatch({
          type: ACTION_CONST.GET_ADAPAD_BALANCE,
          data: data
        })
        setADAPADBalance(data)
      })
    }

  }, [account, web3Utils, latestBlock]);

  const [netWorkConnector, setNetworkConnect] = useState("ETH")
  useEffect(()=>{
    if(chainId == 3 || chainId == 1  ){
      setNetworkConnect("ETH")
    }
    if(chainId ==56 || chainId ==97  ){
      setNetworkConnect("BSC")
    }
  },[chainId])
  




  const handleOnclickKyc = () => {
    getKYC(account, 'url').then(data => {
      if (data) {
        const url = data.url
        window.open(url, "_blank")
      }

    }).catch(err => {
      console.log(err);
    })
  }


  // //check show hide status
  useEffect(() => {
    if (!account) {
      return;
    }
    if ((Number(adapadBalance) + Number(stakedAmount)) >= 2000.0) {
      getKYCAddress(account)
      setEnableKYC(true)
    }else{
      setEnableKYC(false)
    }
  }, [account, adapadBalance, stakedAmount, latestBlock])

  //get kyc
  const getKYCAddress = (address) => {
    getKYC(address, 'state').then(response => {
      address = address.toLowerCase()
      if (response) {
        const state = response.state;
        if (state === 1) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'START'
          })
        }
        if (state === 2) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'PENDING'
          })
        }
        if (state === 3) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'APPROVED'
          })
        }
        if (state === 4) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'ERROR'
          })
        }
      }

    }).catch(err => {
      console.log(err);
    })
  }


  return (
    <>
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
          <Navbar.Brand href="https://adapad.io/">
            <img src="/images/logo_w.png" width="60" alt="ADAPAD" className="me-2" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <button className="nav-link btn btn-primary btn-help d-block d-lg-none" type="button" data-bs-toggle="modal" data-bs-target="#helpModal">
            <i className="mdi mdi-help"></i>
          </button>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="navbar-nav flex-row justify-content-start my-lg-0 my-3">
              <Nav.Item className="nav-item">
                <Nav.Link as={NavLink} activeClassName="active" to={ROUTES.STACKING}>Staking</Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav-item">
                <Nav.Link as={NavLink} activeClassName="active" to={ROUTES.UN_STACKING}>UnStaking</Nav.Link>
              </Nav.Item>
              <Nav.Item className="nav-item">
                <a href={`https://app.adapad.io/projects`} target="blank" aria-current="page" className="nav-link">
                  <span>Projects</span>
                </a>
              </Nav.Item>
            </Nav>
            <Nav className="ms-auto mt-2 mt-lg-0 column-gap-12px mb-lg-0 mb-3">
              {
                !account ?
                  <Nav.Item className="w-mb-100 d-flex">
                    <Nav.Link as="button" className="btn btn-primary w-mb-100" type="button" data-bs-toggle="modal" data-bs-target={showModalHelp ? "#helpModal" : "#connectWalletModal"}>
                      <span>Connect Wallet</span>
                    </Nav.Link>
                    {/* <span className="network-connected">{netWorkConnector}</span> */}
                  </Nav.Item>
                  :
                  <>
                  <Nav.Item className="w-mb-100 d-flex">
                    <Nav.Link as="button" className="nav-link btn btn-primary btn-balance" type="button" data-bs-toggle="modal" data-bs-target="#walletModal">
                      <span>{helpers.formatTransactionHash(account, 5, 5)}</span>&nbsp;-&nbsp;<b>{helpers.formatNumberDownRoundWithExtractMax(adapadBalance, 4)}</b>&nbsp;ADAPAD
                    </Nav.Link>
                    {/* <span className="network-connected">{netWorkConnector}</span> */}
                  </Nav.Item>
                  {
                    enableKYC &&
                    <>
                      {
                        kycStatus === 'START' &&
                        <Nav.Item className="w-mb-100 d-flex">
                          <Nav.Link as="button" className="nav-link btn btn-warning btn-sm" type="button" onClick={() => handleOnclickKyc()} >
                              <i className="mdi mdi-file-edit-outline me-1"></i>
                              <span>{'KYC'}</span>
                          </Nav.Link>
                    
                        </Nav.Item>

                      }
                      {
                        kycStatus === 'PENDING' &&
                        <Nav.Item className="w-mb-100 d-flex">
                          <Nav.Link as="button" className="nav-link btn btn-primary btn-sm" type="button" onClick={() => handleOnclickKyc()} >
                              <i className="mdi mdi-clock-outline me-1"></i>
                              <span>{'KYC'}</span>
                          </Nav.Link>
                    
                        </Nav.Item>
                      }
                      {
                        kycStatus === 'APPROVED' &&

                        <Nav.Item className="w-mb-100 d-flex">
                          <Nav.Link as="button" className="nav-link btn btn-success btn-sm" type="button" >
                            <i className="mdi mdi-check me-1"></i>
                              <span>{'KYC'}</span>
                          </Nav.Link>
                    
                        </Nav.Item>
                     

                      }
                      {
                        kycStatus === 'ERROR' &&
                        <Nav.Item className="w-mb-100 d-flex">
                          <Nav.Link as="button" className="nav-link btn btn-danger btn-sm" type="button" onClick={() => handleOnclickKyc()} >
                            <i className="mdi mdi-close me-1"></i>
                              <span>{'KYC'}</span>
                          </Nav.Link>
                    
                        </Nav.Item>
                      }
                    </>
                  }
                  </>
              }
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
