export const extensionName = {
    metamask: "METAMASK",
    binanceExtension: "BINANCE_EXTENSION",
    trustWallet: "TRUST_WALLET",
};


export const MESSAGE = {
    APPROVAL_FAILS: 'Failed to Approve Tokens!',
    APPROVE_SUCCESS: 'Approve Tokens successfully!',

    DEPOSIT_STAKE_FAIL:'Deposit stake fail!',
    DEPOSIT_STAKE_SUCCESS: 'Deposit stake successfully!',

    UNSTAKE_FAIL:'Unstake fails!',
    UNSTAKE_SUCCESS_FULL:'Unstake successfully!',

    WITHDRAW_FAIL:'Withdraw stake fail!',

    ADD_TOKEN_METAMASK_FAIL:'Add token to metamask fail!',
    ADD_TOKEN_METAMASK_SUCCESS:'Add token to metamask successfully!',

    WRONG_NET_WORK:'Wrong network! You need connect to Binance Smart Chain  network!'
    
}


export const STATE ={
    NONE:"none",
    SUCCESS:'success',
    ERROR: 'error',
    SUBMIT:'submit'
}