


export const POOL_INTERVAL = 15000;

export const EXPLORER = {
    1:'https://etherscan.io',
    3:'https://ropsten.etherscan.io',
    56:'https://bscscan.com',
    97:'https://testnet.bscscan.com'
}


export const STAKING_CONTRACT_ADDRESS ={
    // 1:'0x0Fa10d440522D4235c55811DC1aDf4a875C16d00',
    // 3:'0x1c5489334AbD6f527b00C23206a818321fedd406',
    56:'0xEC44380d903A30543A6E028a9084209aA774aa2d',
    // 97:'0xbf40C8423328311D7AB6263Ba839b8e580A8B170'
}
export const NODE_RPC ={
    1:process.env.REACT_APP_NODE_INFURA_URI_1,
    3:'https://ropsten.infura.io/v3/f2473914890349138c8b03e3ef79d165',
    56:'https://bsc-dataseed.binance.org/',
    // 97:'https://data-seed-prebsc-1-s1.binance.org:8545/',

}

export const defaultChainId = 56;

export const chainSupport = [56]


export const KYC_BACK_END_URI = 'https://bscpad.com'