import React from "react";
const TermsOfUsePage = (props) => {
  return (
    <>
      <div className="container py-4">
        <h1 style={{marginRight: '0cm', marginLeft: '0cm', fontSize: '32px', fontFamily: '"Times New Roman",serif', fontWeight: 'bold', textAlign: 'center'}}><span style={{fontFamily: '"Arial",sans-serif'}}>TERMS AND CONDITIONS</span></h1>
        <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: '18.0pt'}}><span style={{fontFamily: '"Arial",sans-serif'}}>These terms and conditions (<strong>“Terms”</strong>) govern the use of the ADAPAD Application (defined below) and website and the Services (defined below). These Terms also include ADAPAD’s privacy policy, available below&nbsp;(<strong>“Privacy Policy”</strong>), and any guidelines, additional terms, policies, and disclaimers made available or issued by ADAPAD from time to time.</span></p>
        <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: '18.0pt'}}><span style={{fontFamily: '"Arial",sans-serif'}}>These Terms constitute a binding and enforceable legal contract between ADAPAD and its affiliates and subsidiaries worldwide (<strong>“ADAPAD”</strong>, <strong>“we”</strong>, <strong>“us”</strong>) and you, an end user of the Services (<strong>“you”</strong> or <strong>“User”</strong>) in relation to the Services.</span></p>
        <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: '18.0pt'}}><span style={{fontFamily: '"Arial",sans-serif'}}>By using the Services, you agree that you have read, understood, and to are bound by these Terms, as amended from time to time, and that you comply with the requirements listed herein. If you do not agree to all of these Terms or comply with the requirements herein, please do not access or use the Services.</span></p>
        <h2 style={{marginTop: '36.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '17px', fontFamily: '"Calibri Light",sans-serif', color: '#2F5496', fontWeight: 'normal'}}><span style={{fontFamily: '"Arial",sans-serif'}}>1. SERVICES</span></h2>
        <ol start={1} style={{marginBottom: '0cm', marginTop: '0cm'}} type="a">
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>The ADAPAD platform (<strong>“Platform”</strong>) facilitates token swaps. Projects may make available their native tokens (<strong>“Pool”</strong>) to the Users of the Platform to be swapped for other crypto assets. Users may use the Platform to gain access to the tokens offered through the Platform (<strong>“Services”</strong>), which term includes the underlying application, all services available through the Application, and all free trials and beta services made available by ADAPAD.</span></li>
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>In order to avail access to the platform, the User shall stake the ADAPAD token. Details regarding staking shall be made available on the ADAPAD medium page as and when they are updated.</span></li>
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>The User is required to connect their BEP-20 wallet to the Platform to gain access to the Pool. We are not responsible for any loss or damage that may arise from such integration, including any loss arising from any purchase of tokens from the Pool.</span></li>
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>You understand that the Platform only permits access to the Pool and in no way guarantees and warrants performance of such token post purchase through the Platform. Furthermore, allocation is not guaranteed and it shall be on a first come first serve basis.</span></li>
        </ol>
        <h2 style={{marginTop: '36.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '17px', fontFamily: '"Calibri Light",sans-serif', color: '#2F5496', fontWeight: 'normal'}}><span style={{fontFamily: '"Arial",sans-serif'}}>2. ACCESS TO THE SERVICES</span></h2>
        <ol start={1} style={{marginBottom: '0cm', marginTop: '0cm'}} type="a">
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>By using the Services, you represent and warrant that:</span>
            <ol start={1} style={{marginBottom: '0cm', marginTop: '0cm'}} type="I">
              <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>you have full legal capacity and authority to agree and bind yourself to these Terms;</span></li>
              <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>you are eighteen years of age or older;</span></li>
              <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>your use of the Services is (A) not prohibited by applicable law, and (B) at all times compliant with applicable law;</span></li>
              <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>and are solely responsible for use of the Services and, if applicable, for all activities that occur on or through your User Account.</span></li>
            </ol>
          </li>
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>You should take steps to ensure the confidentiality of your personal information and restrict access to the devices you use to access the Platform.</span></li>
        </ol>
        <h2 style={{marginTop: '36.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '17px', fontFamily: '"Calibri Light",sans-serif', color: '#2F5496', fontWeight: 'normal'}}><span style={{fontFamily: '"Arial",sans-serif'}}>3. YOUR RESPONSIBILITIES</span></h2>
        <ol start={1} style={{marginBottom: '0cm', marginTop: '0cm'}} type="a">
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>You shall not use the Services in any manner except as expressly permitted in these Terms. Without limiting the generality of the preceding sentence, you may not:</span>
            <ol start={1} style={{marginBottom: '0cm', marginTop: '0cm'}} type="I">
              <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>infringe any proprietary rights, including but not limited to copyrights, patents, trademarks, or trade secrets of ADAPAD;</span></li>
              <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>use the Services to transmit any data or send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware, or any other harmful programmes or similar computer code designed to adversely affect the operation of any computer software or hardware;</span></li>
              <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>use any robot, spider, other automated device, or manual process to monitor or copy the Services or any portion thereof other than copying or exporting as permitted by the Terms;</span></li>
              <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>make any back-up or archival copies of the Platform or any part thereof, including disassembling or de-compilation of the Platform;</span></li>
              <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>use the Services in (A) any unlawful manner, (B) for fraudulent or malicious activities, or (C) in any manner inconsistent with these Terms;</span></li>
              <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>or violate applicable laws in any manner.</span></li>
            </ol>
          </li>
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>You warrant that you are aware of applicable laws and regulations governing your use of the Services. You shall be solely responsible for ensuring compliance with the various applicable laws, and you shall be solely liable for any liability that may arise due to a breach of your obligations in this regard.</span></li>
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>You shall extend all cooperation, at your cost, to ADAPAD in its defence of any proceedings that may be initiated against it due to a breach of your obligations or covenants under these Terms.</span></li>
        </ol>
        <h2 style={{marginTop: '36.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '17px', fontFamily: '"Calibri Light",sans-serif', color: '#2F5496', fontWeight: 'normal'}}><span style={{fontFamily: '"Arial",sans-serif'}}>4. ADAPAD’S INTELLECTUAL PROPERTY</span></h2>
        <ol start={1} style={{marginBottom: '0cm', marginTop: '0cm'}} type="a">
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>All rights, title, and interest in and to the Services, including all intellectual property rights arising out of the Services, are owned by or otherwise licensed to ADAPAD. Subject to your compliance with these Terms, ADAPAD grants you a non-exclusive, non-sub licensable, and limited license to (i) use the Services in the permitted hereunder.</span></li>
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>Except as stated in these Terms, nothing in these Terms should be construed as conferring any right in or license to ADAPAD’s or any third party’s intellectual rights.</span></li>
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>You may provide suggestions and other feedback, including bug reports, in relation to the Services from time to time (“Feedback”). ADAPAD may freely use, copy, disclose, publish, display, distribute, and exploit the Feedback without any payment of royalty, acknowledgement, prior consent, or any other form of restriction arising out of your intellectual property rights.</span></li>
        </ol>
        <h2 style={{marginTop: '36.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '17px', fontFamily: '"Calibri Light",sans-serif', color: '#2F5496', fontWeight: 'normal'}}><span style={{fontFamily: '"Arial",sans-serif'}}>5. THIRD PARTY SERVICES</span></h2>
        <ol start={1} style={{marginBottom: '0cm', marginTop: '0cm'}} type="a">
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>The Services may include services, content, and information owned, made available, or otherwise licensed by a third party (“Third Party Services”) or contain links to Third Party Services. You understand that Third Party Services are the responsibility of the third party that created or provided it and acknowledge that the use of such Third Party Services is solely at your own risk.</span></li>
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>ADAPAD makes no representations and excludes all warranties and liabilities arising out of or pertaining to such Third Party Services, including its accuracy or completeness.</span></li>
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>All intellectual property rights in and to Third Party Services are the property of the respective third parties.</span></li>
        </ol>
        <h2 style={{marginTop: '36.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '17px', fontFamily: '"Calibri Light",sans-serif', color: '#2F5496', fontWeight: 'normal'}}><span style={{fontFamily: '"Arial",sans-serif'}}>6. INDEMNITY</span></h2>
        <p style={{marginRight: '0cm', marginLeft: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: '18.0pt'}}><span style={{fontFamily: '"Arial",sans-serif'}}>You agree to indemnify and hold harmless ADAPAD, its affiliates, subsidiaries, licensors, and their respective directors, officers, members, managers, employees, and agents from and against any and all claims and expenses arising out of your use of the Services, a breach of any provision of these Terms by you or any person using the Services on your behalf, a breach by you of any applicable laws, or any third-party claim to the extent arising from or connected with an allegation that your use of the Services in accordance with these Terms infringes any rights of a third party.</span></p>
        <h2 style={{marginTop: '36.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '17px', fontFamily: '"Calibri Light",sans-serif', color: '#2F5496', fontWeight: 'normal'}}><span style={{fontFamily: '"Arial",sans-serif'}}>7. TERM AND TERMINATION</span></h2>
        <ol start={1} style={{marginBottom: '0cm', marginTop: '0cm'}} type="a">
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>These Terms shall remain in effect unless terminated in accordance with the terms hereunder.</span></li>
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>ADAPAD may terminate, suspend, or modify your access to the Services, or any portion thereof, immediately and at any point, at its sole discretion if it is of the view that you violate or breach any of your obligations, responsibilities, or covenants under these Terms. ADAPAD will not be liable to you or to any third party for any termination, suspension, or modification of your access to the Services.</span></li>
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>Upon termination under Clause 7(b):</span>
            <ol start={1} style={{marginBottom: '0cm', marginTop: '0cm'}} type="I">
              <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>these Terms shall terminate, except for those clauses that expressly or are intended to survive termination or expiry.</span></li>
            </ol>
          </li>
        </ol>
        <h2 style={{marginTop: '36.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '17px', fontFamily: '"Calibri Light",sans-serif', color: '#2F5496', fontWeight: 'normal'}}><span style={{fontFamily: '"Arial",sans-serif'}}>8. DISCLAIMERS AND WARRANTIES</span></h2>
        <ol start={1} style={{marginBottom: '0cm', marginTop: '0cm'}} type="a">
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>ADAPAD merely facilitates access to the Pools and does not provide you with any warranty or representation whatsoever regarding its quality, fitness for purpose, completeness or accuracy.</span></li>
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>ADAPAD shall make all commercially reasonable attempts to facilitate information about the Pools on the platform (“Project Information”). However, you understand that ADAPAD does not (i) guarantee the accuracy, timeliness, or completeness of such information, (ii) provide any warranties in connection with your use or reliance on such information. You agree that your use of the Project Information at your own risk. ADAPAD shall not be liable to you in any manner for the termination, interruption, delay, or inaccuracy of any Portfolio Information.</span></li>
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>ADAPAD is merely a technology platform, and does not provide any legal, tax, investment, financial, or other advice and no information provided by ADAPAD in connection with the Services should be construed as such.</span></li>
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>You agree that your use of the Services is at your sole risk. To the extent permitted by applicable law, the Services are provided on an “as is” and “as available” basis. ADAPAD does not warrant that that the functions contained in the Services will meet your requirements. You hereby accept full responsibility for any consequences that may arise from your use of the Services, and expressly agree and acknowledge that ADAPAD shall have absolutely no liability in this regard.</span></li>
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>ADAPAD does not warrant or represent that the Services will be compatible with any third party hardware or software. It shall your responsibility to ensure compatibility of the Services prior to use. Additionally, ADAPAD shall not be held responsible for any actual, incidental or consequential damages that may result from any use or inability to use any third-party peripherals with the Services.</span></li>
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>The Application may contain links to third party web sites or services that are not owned or controlled by ADAPAD. ADAPAD has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any such websites. You further acknowledge and agree that ADAPAD shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or service.</span></li>
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>To the fullest extent permissible under applicable law, ADAPAD expressly disclaims all warranties of any kind, express or implied, arising out of the Services, including warranties of merchantability, fitness for a particular purpose, satisfactory quality, accuracy, title and non-infringement, compatibility, applicability, usability, appropriateness, and any warranty that may arise out of course of performance, course of dealing, or usage of trade.</span></li>
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>To the fullest extent permissible by law, ADAPAD, its affiliates, and their related parties each disclaim all liability to you for any loss or damage arising out of or due to:</span>
            <ol start={1} style={{marginBottom: '0cm', marginTop: '0cm'}} type="I">
              <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>your use of, inability to use, or availability or unavailability of the Services, including any third party services made available through the Services;</span></li>
              <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>the occurrence or existence of any defect, interruption, deletion of files, delays in the operation or transmission of information to, from, or through the Services, communications failure, theft, destruction or unauthorised access to ADAPAD’s records, programs, services, server, or other infrastructure relating to the Services;</span></li>
              <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>the Services being infected with any malicious code or viruses; or</span></li>
              <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>the failure of the Services to remain operational for any period of time.</span></li>
            </ol>
          </li>
        </ol>
        <h2 style={{marginTop: '36.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '17px', fontFamily: '"Calibri Light",sans-serif', color: '#2F5496', fontWeight: 'normal'}}><span style={{fontFamily: '"Arial",sans-serif'}}>9. CONSENT TO USE DATA</span></h2>
        <ol start={1} style={{marginBottom: '0cm', marginTop: '0cm'}} type="a">
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>You agree that ADAPAD may in accordance with its Privacy Policy collect and use your information and technical data and related information.</span></li>
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>ADAPAD may use information and anonymised data pertaining to your use of the Services for analytics, trends’ identification, and purposes of statistics to further enhance the effectiveness and efficiency of the Services.</span></li>
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>You hereby expressly authorise ADAPAD to disclose any and all information relating to you in ADAPAD’s possession to any law enforcement or other government officials, if ADAPAD believes it is necessary or appropriate in connection with the investigation or resolution of possible crimes. You further understand that ADAPAD might be directed to disclose any such information as may be deemed necessary to satisfy any investigation, judicial order, law, regulation, or valid governmental request.</span></li>
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>You acknowledge that in the event of any security breach on the Platform, ADAPAD reserves the right to make necessary disclosures to applicable authorities established under law.</span></li>
        </ol>
        <h2 style={{marginTop: '36.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '17px', fontFamily: '"Calibri Light",sans-serif', color: '#2F5496', fontWeight: 'normal'}}><span style={{fontFamily: '"Arial",sans-serif'}}>10. CONFIDENTIALITY</span></h2>
        <p style={{marginRight: '0cm', marginLeft: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: '18.0pt'}}><span style={{fontFamily: '"Arial",sans-serif'}}>You acknowledge that the Services contain ADAPAD’s and its licensors’ trade secrets and confidential information. You agree to hold and maintain the Services in confidence, and not to furnish any other person with a copy of the Services or Platform. You agree to use a reasonable degree of care to protect the confidentiality of the Services. You will not remove or alter any of ADAPAD’s or its licensors’ proprietary notices. Your obligations under this Clause continue even after these Terms have expired or been terminated.</span></p>
        <h2 style={{marginTop: '36.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '17px', fontFamily: '"Calibri Light",sans-serif', color: '#2F5496', fontWeight: 'normal'}}><span style={{fontFamily: '"Arial",sans-serif'}}>11. Know your Customer (“KYC”) and Anti-Money Laundering (“AML”) Policy</span></h2>
        <ol start={1} style={{marginBottom: '0cm', marginTop: '0cm'}} type="a">
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>ADAPAD is a permissionless and fully decentralised platform for token sales and swaps. As a software development company, ADAPAD has no role in enforcing KYC by default; however, we do provide such tools for fundraising entities using ADAPAD to enforce on their users, if the entities choose to do so. The use of KYC/AML tools on ADAPAD by fundraising entities using the Service is completely at the discretion of said entities.</span></li>
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>Although ADAPAD makes no warranty as to the merit, legality or juridical nature of any token (including whether or not it is considered a security or financial instrument under any applicable securities laws), we nonetheless understand the need of some token projects to require KYC/AML on their token sale participants.</span></li>
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>Therefore, and at the sole behest of fundraising entities and/or competent regulatory authorities, ADAPAD reserves the right:</span>
            <ol start={1} style={{marginBottom: '0cm', marginTop: '0cm'}} type="I">
              <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>at any time, to ask for any KYC documentation it deems necessary to determine the identity and location of a User, and reserves the right to restrict Service and payment until identity is sufficiently determined;</span></li>
              <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>to share submitted KYC information and documentation to 3rd parties to verify the authenticity of submitted information, and the end user (you) agree to this by using the Service;</span></li>
              <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>to confiscate any and all funds that are found to be in violation of relevant and applicable AML and countering terrorism financing (“CFT”) laws and regulations, and to cooperate with the competent authorities when and if necessary.</span></li>
            </ol>
          </li>
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>ADAPAD expressly prohibits and rejects the use of the Service for any form of illicit activity, including money laundering, terrorist financing or trade sanctions violations, consistent with various jurisdictions’ laws, regulations and norms. To that end, the Service is not offered to individuals or entities on any Politically Exposed Persons (“PEP”) lists, or subject to any United States, European Union, or other global sanctions or watch lists. By using the Service, you represent that you are not on any such lists.</span></li>
        </ol>
        <h2 style={{marginTop: '36.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '17px', fontFamily: '"Calibri Light",sans-serif', color: '#2F5496', fontWeight: 'normal'}}><span style={{fontFamily: '"Arial",sans-serif'}}>12. FORCE MAJEURE</span></h2>
        <p style={{marginRight: '0cm', marginLeft: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: '18.0pt'}}><span style={{fontFamily: '"Arial",sans-serif'}}>ADAPAD shall have no liability to you if it is prevented from or delayed in performing its obligations or from carrying on its business, by acts, events, omissions or accidents beyond its reasonable control, including, without limitation, strikes, failure of a utility service or telecommunications network, act of God, war, riot, civil commotion, malicious damage, compliance with any law or governmental order, rule, regulation, or direction.</span></p>
        <h2 style={{marginTop: '36.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '17px', fontFamily: '"Calibri Light",sans-serif', color: '#2F5496', fontWeight: 'normal'}}><span style={{fontFamily: '"Arial",sans-serif'}}>13. JURISDICTION AND GOVERNING LAW</span></h2>
        <ol start={1} style={{marginBottom: '0cm', marginTop: '0cm'}} type="a">
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>Subject to the other provisions of this Clause 13, the Parties shall attempt in good faith to mutually resolve any and all disputes, whether of law or fact, and of any nature whatsoever arising from or with respect to this Agreement.</span></li>
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>All disputes shall be resolved by arbitration in Panama in accordance with the procedural aspects of Panama Arbitration Law (Law No. 131 of December 31, 2013 ) for the time being in force and rules thereunder, which are deemed to be incorporated by reference in this Clause. The tribunal shall consist of 1 (One) arbitrator mutually appointed by the Parties. The seat of the arbitration shall be Panama, and the language of the arbitration shall be English. The parties may attend such proceedings via video conferencing as far as permissible under law.</span></li>
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontFamily: '"Arial",sans-serif'}}>The award rendered by such arbitrator shall be final and binding on the Parties.</span></li>
        </ol>
        <h2 style={{marginTop: '36.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '107%', fontSize: '17px', fontFamily: '"Calibri Light",sans-serif', color: '#2F5496', fontWeight: 'normal'}}><span style={{fontFamily: '"Arial",sans-serif'}}>14. MISCELLANEOUS PROVISIONS</span></h2>
        <ol start={1} style={{marginBottom: '0cm', marginTop: '0cm'}} type="a">
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><u><span style={{fontFamily: '"Arial",sans-serif'}}>Modification</span></u><span style={{fontFamily: '"Arial",sans-serif'}}>: ADAPAD reserves the right at any time to modify these Terms and to add new or additional terms or conditions on your use of the Services. Such modifications and additional terms and conditions will be communicated to you and, unless expressly rejected (in which these Terms shall terminate), will be effective immediately and will be incorporated into these Terms. In the event, you refuse to accept such changes, these Terms and licence will terminate.</span></li>
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><u><span style={{fontFamily: '"Arial",sans-serif'}}>Severability</span></u><span style={{fontFamily: '"Arial",sans-serif'}}>: If any provision of these Terms is determined by any court or other competent authority to be unlawful or unenforceable, the other provisions of these Terms will continue in effect. If any unlawful or unenforceable provision would be lawful or enforceable if part of it were deleted, that part will be deemed to be deleted, and the rest of the provision will continue in effect (unless that would contradict the clear intention of the clause, in which case the entirety of the relevant provision will be deemed to be deleted).</span></li>
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><u><span style={{fontFamily: '"Arial",sans-serif'}}>Notices</span></u><span style={{fontFamily: '"Arial",sans-serif'}}>: All notices, requests, demands, and determinations for ADAPAD under these Terms (other than routine operational communications) shall be sent to [info(at)adapad.io].</span></li>
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><u><span style={{fontFamily: '"Arial",sans-serif'}}>Third Party Rights</span></u><span style={{fontFamily: '"Arial",sans-serif'}}>: No third party shall have any rights to enforce any terms contained herein.</span></li>
        </ol>
      </div>
    </>
  );
};

export default TermsOfUsePage;
