import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import $ from 'jquery';
import { Link } from "react-router-dom";
import { ACTION_CONST, ACTION_STATUS, MESSAGE, ROUTES } from "../../constants";
import { EXPLORER  } from "../../_configs";
import { Button } from "react-bootstrap";
import { BigNumber } from "bignumber.js";
import { helpers } from "../../utils";
import { useTranslation } from "react-i18next";
import { StakingLayout } from "../../component/layout/StakingLayout";
import { useActiveWeb3React } from "../../hook";
import { useEthBalance, useNativeCoin, useNetWork, useStakingInfo, useStakingWalletInfo, useWeb3Utils } from "../../hook/useState";

const UnStakingPage = forwardRef((props, ref) => {
    const {chainId, account} = useActiveWeb3React()
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [unStakeCurrentStep, setUnStakeCurrentStep] = useState(1);
    const web3Utils = useWeb3Utils();
    const stakingInfo = useStakingInfo()
    const stakingWalletInfo = useStakingWalletInfo()
    const ethBalance = useEthBalance();

    const [acceptTerm, setAcceptTerm] = useState(false);
    const [enableUnStakeBtn, setEnableUnStakeBtn] = useState(false);
    const [transactionHash, setTransactionHash] = useState("");
    const [unStakeAmount, setUnStakeAmount] = useState('0');
    const [stakedDuration, setStakedDuration] = useState(0);
    const [totalPctFee, setTotalPctFee] = useState(0);
    const [isMaxAmount, setIsMaxAmount] = useState(false);
    const [isSubmitOK, setIsSubmitOK] = useState(false);


    useEffect(() => {
        setUnStakeStepActive();
    }, [unStakeCurrentStep])

    const cleanValue = () => {
        setUnStakeCurrentStep(1);
    };
    useImperativeHandle(ref, () => {
        return {
            cleanValue: cleanValue
        }
    });

    //enable unstake button
    useEffect(() => {
        if (unStakeCurrentStep === 2) {
            if (acceptTerm &&
                account &&
                !stakingInfo["isStakingPaused"] &&
                parseFloat(stakingWalletInfo["stakedAmount"]) > 0 &&
                ethBalance > 0
            ) {
                setEnableUnStakeBtn(true);
            } else {
                setEnableUnStakeBtn(false);
            }
        }
        if (unStakeCurrentStep === 1) setEnableUnStakeBtn(true);
        if (stakingInfo && stakingWalletInfo["stakedDuration"]) {
            setStakedDuration(stakingWalletInfo["stakedDuration"]);
            setTotalPctFee(stakingWalletInfo["totalPctFee"] / 100);
        }
    }, [acceptTerm, account, ethBalance, stakingInfo, stakingWalletInfo, unStakeCurrentStep])


    const unStakeBack = () => {
        if (unStakeCurrentStep === 1) {
            return;
        } else {
            setUnStakeCurrentStep(unStakeCurrentStep - 1);
        }
    }

    const unStakeNext = () => {
        if (unStakeCurrentStep === 1) {
            setEnableUnStakeBtn(false);
            setUnStakeCurrentStep(unStakeCurrentStep + 1);
            return;
        }

        if (unStakeCurrentStep === 2 && parseFloat(unStakeAmount) === 0) {
            setEnableUnStakeBtn(false);
            setUnStakeCurrentStep(unStakeCurrentStep + 1);
            return;
        }

        if (unStakeCurrentStep === 4) {
            if (web3Utils) {
                dispatch({
                    type: ACTION_CONST.REQUEST_SUBMIT
                })
                web3Utils.stakingInitiateWithdrawal({ amount: isMaxAmount ? stakingWalletInfo["stakedAmount"] : unStakeAmount }, data => {
                    if (data.status === ACTION_STATUS.STAKING_INITIATE_WITHDRAWAL_SUCCESS) {
                        dispatch({type: ACTION_CONST.SET_SHOW_TRANSACTION_HASH_MODAL, data: data.txID});
                        dispatch({type: ACTION_CONST.SET_MESSAGE, data: MESSAGE.UNSTAKE_SUCCESS_FULL});
                        dispatch({type: ACTION_CONST.REQUEST_SUCCESS})
                        setIsSubmitOK(true);
                        setTransactionHash(data.txID);
                        setUnStakeCurrentStep(5);
                    }
                    if (data.status === ACTION_STATUS.STAKING_INITIATE_WITHDRAWAL_FAIL) {
                        dispatch({
                            type: ACTION_CONST.REQUEST_FAIL
                        })
                        dispatch({type: ACTION_CONST.SET_MESSAGE, data: MESSAGE.UNSTAKE_FAIL});
                    }
                })
            }
        } else {
            if (unStakeCurrentStep === 5) {
                return;
            } else {
                setUnStakeCurrentStep(unStakeCurrentStep + 1);
            }
        }
    }

    const handleInputUnStake = (e) => {
        setIsMaxAmount(false);
        if (helpers.isFloatFormatted(e.target.value, 4)) {
            setUnStakeAmount(e.target.value);
            //check input
            if (BigNumber(e.target.value).lte(BigNumber(stakingWalletInfo["stakedAmount"])) && BigNumber(e.target.value).gt(BigNumber(0))) {
                setEnableUnStakeBtn(true);
            } else {
                setEnableUnStakeBtn(false);
            }
        }
    }

    const handleMaxButtonClick = () => {
        setUnStakeAmount(helpers.formatNumberDownRound(stakingWalletInfo["stakedAmount"], 4));
        setEnableUnStakeBtn(true);
        setIsMaxAmount(true);
    }

    const submitDone = () => {
        setUnStakeAmount('0');
        setIsMaxAmount(true);
        setUnStakeCurrentStep(1);
        setIsSubmitOK(false);
    }

    function setUnStakeStepActive() {
        $('#unStake .bs-stepper-header .step').removeClass('active');
        $('#unStake .bs-stepper-header .line').removeClass('active');
        for (var i = 1; i <= 5; i++) {
            if (i <= unStakeCurrentStep) {
                $('#unStake #unStakeButtonStep' + i).addClass('active');
                $('#unStake #unStakeLineStep' + (i - 1)).addClass('active');
            }
        }
        $('#unStake .bs-stepper-content').hide();
        $('#unStake #UnStakeStep' + unStakeCurrentStep).show();
    }

    return (
        <StakingLayout title={t('Unstake & Withdraw your ADAPAD')}>
            <div id="unStake">
                <div className="row justify-content-center mb-4">
                    <div className="col-lg-12">
                        <div className="bs-stepper w-100">
                            <div className="bs-stepper-header" role="tablist">
                                <div className="step active" id="unStakeButtonStep1">
                                    <button type="button" className="step-trigger ps-0">
                                        <img src="/images/cir.svg" />
                                        <span className="bs-stepper-label first">Warning</span>
                                    </button>
                                </div>
                                <div className="line" id="unStakeLineStep1" />
                                <div className="step" id="unStakeButtonStep2">
                                    <button type="button" className="step-trigger">
                                        <img src="/images/cir.svg" />
                                        <span className="bs-stepper-label">Checkpoints</span>
                                    </button>
                                </div>
                                <div className="line" id="unStakeLineStep2" />
                                <div className="step" id="unStakeButtonStep3">
                                    <button type="button" className="step-trigger">
                                        <img src="/images/cir.svg" />
                                        <span className="bs-stepper-label">Amount to Unstake</span>
                                    </button>
                                </div>
                                <div className="line" id="unStakeLineStep3" />
                                <div className="step" id="unStakeButtonStep4">
                                    <button type="button" className="step-trigger">
                                        <img src="/images/cir.svg" />
                                        <span className="bs-stepper-label">Initialize Unstake</span>
                                    </button>
                                </div>
                                <div className="line" id="unStakeLineStep4" />
                                <div className="step" id="unStakeButtonStep5">
                                    <button type="button" className="step-trigger">
                                        <img src="/images/cir.svg" />
                                        <span className="bs-stepper-label last">Confirmation</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="p-sidebar-card p-sidebar-card-steps">
                            <div className="p-sidebar-card-body">
                                <div className="bs-stepper-content" id="UnStakeStep1">
                                    <div className="mt-2">
                                        <div className="bs-stepper-warning">
                                            <i className="fas fa-exclamation-triangle me-2"></i>
                                            <span>{t('You may be subject to a fee if you wish to unstake & withdraw your ADAPAD tokens early')}</span>
                                        </div>
                                        <div className="row justify-content-center mb-4">
                                            <div className="col-xl-4 col-md-8">
                                                <div className="table-responsive">
                                                    <table className="table text-white bs-stepper-table">
                                                        <tbody>
                                                            <tr>
                                                                <td className="text-start">{t('Staked less than 2 weeks')}</td>
                                                                <td className="text-end">
                                                                    <h5 className="mb-0">25.00%</h5>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-start">{t('Less than 4 weeks')}</td>
                                                                <td className="text-end">
                                                                    <h5 className="mb-0">15.00%</h5>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-start">{t('Less than 6 weeks')}</td>
                                                                <td className="text-end">
                                                                    <h5 className="mb-0">10.00%</h5>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-start">{t('Less than 8 weeks')}</td>
                                                                <td className="text-end">
                                                                    <h5 className="mb-0">5.00%</h5>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="text-start">{t('8 weeks or more')}</td>
                                                                <td className="text-end">
                                                                    <h5 className="mb-0">0.00%</h5>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <p className="mb-2">{t('You have staked for')} [{helpers.convertSecondsToReadableString(stakedDuration)}]. {t('Your current fee is')}: <b className="text-white">{totalPctFee}%</b></p>
                                            <p>{t("Please click 'Next' if you wish to proceed.")}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="bs-stepper-content" id="UnStakeStep2">
                                    <div className="text-center">
                                        <h4>{t('Checkpoints')}</h4>
                                        <p>{t('The following conditions must be met to proceed')}:</p>
                                    </div>
                                    <div className="row mt-5 gx-lg-5 d-flex justify-content-xl-center justify-content-lg-start justify-content-center">
                                        <div className="col-xl-4 col-md-6 mb-lg-0 mb-3">
                                            <div className={account ? "p-select-card mb-4 selected" : "p-select-card mb-4"}>
                                                <div className="p-select-card-title"><b>{t('Connected with MetaMask')}</b></div>
                                                <div className="p-select-card-description">{t('If not connected, click the "Connect Wallet" button in the top right corner')}</div>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-md-6 mb-lg-0 mb-3">
                                            <div className={parseFloat(stakingWalletInfo["stakedAmount"]) > 0 ? "p-select-card mb-4 selected" : "p-select-card mb-4"}>
                                                <div className="p-select-card-title"><b>{t('Have an active ADAPAD stake')}</b></div>
                                                <div className="p-select-card-description">{t('You currently have')} {helpers.formatNumberDownRound(stakingWalletInfo["stakedAmount"], 2)} {t('ADAPAD staked')}</div>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-md-6 mb-lg-0 mb-3">
                                            <div className={ethBalance > 0 ? "p-select-card mb-4 selected" : "p-select-card mb-4"}>
                                                <div className="p-select-card-title"><b>{useNativeCoin(chainId)} available in wallet.</b></div>
                                                <div className="p-select-card-description">
                                                    {useNativeCoin(chainId)} is required to pay transaction fees on the {useNetWork(chainId)}.<br />
                                                    {useNativeCoin(chainId)}: {helpers.formatNumberDownRoundWithExtractMax(ethBalance, 4)}
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-3 col-md-6 mb-lg-0 mb-3">
                                        <div className={stakingWalletInfo["withdrawTimestamp"] === 0 ? "p-select-card mb-4 selected" : "p-select-card mb-4"}>
                                            <div className="p-select-card-title"><b>{t('Eligible to initiate unstake')}</b></div>
                                            <div className="p-select-card-description">{t('You cannot unstake if you already have an active ADAPAD unstake/withdrawal request')}</div>
                                        </div>
                                    </div> */}
                                    </div>
                                    <div className="mt-5 text-center">
                                        <div className="form-check">
                                            <input className="form-check-input float-none me-1"

                                                type="checkbox" defaultValue id="flexCheckDefault"
                                                onChange={() => setAcceptTerm(!acceptTerm)} />
                                            <label className="form-check-label ms-1" htmlFor="flexCheckDefault">
                                                {t('I have read the')}&nbsp;
                                                <Link target="_blank" to={ROUTES.TERMS_OF_USE}>{t('Terms and Conditions')} </Link>
                                                {/* <a href="#">Terms and Conditions</a> */}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="bs-stepper-content" id="UnStakeStep3">
                                    <div className="text-center">
                                        <h4>{t('Please enter the amount you wish to Unstake & Withdraw')}</h4>
                                        <div className="row justify-content-center mt-5">
                                            <div className="col-xl-5 px-xl-1 col-lg-6 col-8 mb-1">
                                                <label className="form-label">{t('Amount')}</label>
                                                <div className="input-group">
                                                    <input type="number" className="form-control fs-24" placeholder={0.0} value={unStakeAmount} onChange={(e) => handleInputUnStake(e)} />
                                                    <Button type="button" className="mw-0 px-3" onClick={handleMaxButtonClick}>{t('MAX')}</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bs-stepper-content" id="UnStakeStep4">
                                    <div className="text-center">
                                        <h4>{t('Confirm Unstaking & Withdraw Process')}</h4>
                                        <p>{t('In this step, you initiate the process')}</p>
                                    </div>
                                </div>
                                <div className="bs-stepper-content" id="UnStakeStep5">
                                    <div className="text-center">
                                        <h4>{t('Confirmed')}</h4>
                                        <div className="text-white">
                                            <i className="mdi mdi-check" style={{ fontSize: '50px' }} />
                                        </div>
                                        <p>
                                            {t('You have initiated the process.')}<br />
                                            If desired, you may check {useNetWork(chainId)} to confirm the transaction
                                        </p>
                                        <p><a className="p-address" href={`${EXPLORER[chainId]}/tx/${transactionHash}`} target="blank">{transactionHash}</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb-3 mt-5 text-center step-buttons">
                    {!isSubmitOK ?
                        <>
                            <button onClick={() => unStakeBack()} type="button"
                                disabled={!enableUnStakeBtn || unStakeCurrentStep === 1}
                                className="btn btn-primary btn-back-step me-3"><i className="mdi mdi-arrow-left me-2" />{t('Previous')}</button>
                            <button onClick={() => unStakeNext()} type="button"
                                disabled={!enableUnStakeBtn || unStakeCurrentStep === 5}
                                className="btn btn-primary btn-next-step">{t('Next')}<i className="mdi mdi-arrow-right ms-2" /></button></>
                        :
                        <>
                            <button onClick={() => submitDone()} type="button"
                                className="btn btn-primary me-3">{t('Done')}</button>
                        </>
                    }

                </div>
            </div>
        </StakingLayout>
    );
});

export default UnStakingPage;
