import Web3 from "web3";
import {
  STAKING_CONTRACT_ADDRESS,
  NODE_RPC,
  defaultChainId,
} from "../_configs";
import stakingABIContract from "../constants/abi/staking.json";
import { BigNumber } from "bignumber.js";
import _ from "lodash";

//get info from staking contract
export async function getStakingContractInfo(chainId = defaultChainId) {
  const web3 = new Web3(NODE_RPC[chainId]);
  const stakingContract = new web3.eth.Contract(
    stakingABIContract,
    STAKING_CONTRACT_ADDRESS[chainId]
  );
  try {
    const info = await stakingContract.methods.info().call();
    // console.log("info==>", info);
    return {
      tokenAddr: info[0],
      tokenSymbol: info[1],
      tokenDecimals: Number(info[2]),
      stakerCount: Number(info[3]),
      totalStakedAmount: BigNumber(info[4])
        .dividedBy(10 ** 18)
        .toString(),
      apyPercentage: Number(info[5]),
      unstakePeriod: Number(info[6]),
      isStakingPaused: info[7],
      totalRewardsDistributed: BigNumber(info[8])
        .dividedBy(10 ** 18)
        .toString(),
    };
  } catch (error) {
    console.log(error);
    return {
      tokenAddr: 0,
      tokenSymbol: 0,
      tokenDecimals: 0,
      stakerCount: 0,
      totalStakedAmount: 0,
      apyPercentage: 0,
      unstakePeriod: 0,
      isStakingPaused: 0,
      totalRewardsDistributed: 0,
    };
  }
}

export async function getBlockNumber(chainId = defaultChainId) {
  const web3 = new Web3(NODE_RPC[chainId]);
  return web3.eth.getBlockNumber();
}
