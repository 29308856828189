import {
    isMetamaskAvailable,
    isTrustWalletAvailable,
} from "../utils/utils";
import { ConnectorNames } from "./app";

export const connectors = [{
        title: 'Metamask',
        icon: '/images/metamask.svg',
        connectorId: ConnectorNames.Injected,
        enable: isMetamaskAvailable()
    },
    {
        title: 'TrustWallet',
        icon: '/images/trust.svg',
        connectorId: ConnectorNames.Injected,
        enable: isTrustWalletAvailable()
    }
    // {
    //     title: "WalletConnect",
    //     icon: '/images/WalletConnect.svg',
    //     connectorId: ConnectorNames.WalletConnect,
    //     enable: true
    // }
];