import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ACTION_CONST, MESSAGE } from "../../../../constants";
import { helpers } from "../../../../utils";
import { useActiveWeb3React } from "../../../../hook";
import { getCountDown } from "../../../../utils/helper";
import Socials from "./Socials";
import { ETH_TOKEN_REWARD__BCS_MAINNET } from "../../../../_configs";
import { addTokenToMetamask } from "../../../../utils/metamaskUtils";
import { useLatestBlockNumber, useRewardTokenAddress, useWeb3Utils } from "../../../../hook/useState";

const RightBar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { account, library, chainId } = useActiveWeb3React()
  const web3Utils = useWeb3Utils();
  const latestBlock = useLatestBlockNumber();
  const rewardTokenAddress = useRewardTokenAddress() 
  const [stakedAmount, setStakedAmount] = useState('0');
  const [unstakedAmount, setUnstakedAmount] = useState('0');
  const [rewardAmount, setRewardAmount] = useState('0');
  const [withdrawTimestamp, setWithdrawTimestamp] = useState(0);

  // const [isSubmitOK, setIsSubmitOK] = useState(false);
  const submitOK = useSelector((state) =>get(state, "utils.blocking", false));
  const [tokenReward, setTokenReward] = useState(null); 

  useEffect(() => {
    if (web3Utils && account) {
      // get stake amount
      web3Utils.getStakingInfoWallet().then(data => {
        setStakedAmount(data.stakedAmount);
        setUnstakedAmount(data.unstakedAmount);
        setRewardAmount(data.rewardAmount);
        const now = new Date().getTime();
        if (data.withdrawTimestamp > now) {
          setWithdrawTimestamp(data.withdrawTimestamp);
        }
        dispatch({ type: ACTION_CONST.GET_STAKING_WALLET_INFO, data: data })
      });
    }
  }, [web3Utils, account, submitOK, dispatch, withdrawTimestamp, latestBlock])

  useEffect(() => {
    if (withdrawTimestamp > 0) {
      getCountDown(`${account}-endtime`, withdrawTimestamp, (job) => {
        dispatch({
          type: ACTION_CONST.SET_JOB_COUNTDOWN_STAKE_TIME,
          data: job
        });
      }, (job) => {
        setWithdrawTimestamp(0);
      });
    }
  }, [dispatch, account, withdrawTimestamp]);


  useEffect(()=>{
    if(rewardTokenAddress!=="0x0000000000000000000000000000000000000000" && library && chainId ){
      web3Utils.getTokenInfo([rewardTokenAddress]).then(res=>{
        setTokenReward({
          tokenAddress: rewardTokenAddress,
          tokenSymbol: res[rewardTokenAddress]["symbol"],
          tokenDecimals: res[rewardTokenAddress]["decimal"],
        });
      }).catch(err=>console.log(err))

    }
  },[rewardTokenAddress, library, chainId])

  //handle button withdraw click 
  const handleExcWithDraw = () => {
    if (web3Utils) {
      dispatch({
        type: ACTION_CONST.REQUEST_SUBMIT
      })
      web3Utils.stakingExecuteWithdrawRewards(data => {
        if (data.status === "STAKING_EXECUTE_WITHDRAW_REWARDS_SUCCESS") {
          dispatch({
            type: ACTION_CONST.REQUEST_DONE
          })
          dispatch({
            type: ACTION_CONST.ALERT_SUCCESS,
            message: t("Withdraw stake successfully")
          })
        }
        if (data.status === "STAKING_EXECUTE_WITHDRAW_REWARDS_FAIL") {
          dispatch({
            type: ACTION_CONST.REQUEST_DONE
          })
          dispatch({
            type: ACTION_CONST.ALERT_FAILS,
            message: t("Withdraw stake fail")
          })
        }
      })
    }
    //to do somrthing
  }



  const handleAddTokenToMetamask = () => {
    if(!tokenReward){
     return dispatch({ type: ACTION_CONST.SET_MESSAGE, data: MESSAGE.ADD_TOKEN_METAMASK_FAIL });
    }
    dispatch({
      type: ACTION_CONST.REQUEST_SUBMIT
    })
    addTokenToMetamask(tokenReward, (response) => {
      if (response.status === 'ADD_TOKEN_SUCCESS') {
        dispatch({ type: ACTION_CONST.SET_MESSAGE, data: MESSAGE.ADD_TOKEN_METAMASK_SUCCESS });
        dispatch({ type: ACTION_CONST.REQUEST_SUCCESS })
      }
      if (response.status === 'ADD_TOKEN_FAILS') {

        dispatch({
          type: ACTION_CONST.REQUEST_FAIL
        })
        dispatch({ type: ACTION_CONST.SET_MESSAGE, data: MESSAGE.ADD_TOKEN_METAMASK_FAIL });

      }

    })

  }

  return (
    <div className="p-sidebar" >
      <div className="staking-card style-4 pb-4 mb-lg-0">
        <div className="p-sidebar-card">
          <div className="staking-card-header mb-2">{t('Staked')}</div>
          <div className="staking-card-body">{helpers.formatNumberDownRoundWithExtractMax(stakedAmount, 4)}</div>
        </div>
        <hr className="mb-2" />
        <div className="p-sidebar-card">
          <div className="staking-card-header mb-2">{t('Rewards')} (ADA)</div>
          <div className="staking-card-body">{helpers.formatNumberDownRoundWithExtractMax(rewardAmount, 6)}</div>
          <div className="staking-card-footer mt-4">
            <button className="btn btn-primary w-100"
              disabled={!account || parseFloat(rewardAmount) === 0 || parseFloat(unstakedAmount) > 0}
              onClick={() => handleExcWithDraw()}>{t('Withdraw')}</button>
          </div>

          {(chainId === 56 || chainId === 97) &&
            <div className="staking-card-footer mt-4">
              <button className="btn btn-primary w-100"
                disabled={!account}
                onClick={() => handleAddTokenToMetamask()}>{t('+ Add To Metamask')}</button>
            </div>
          }

        </div>

      </div>

      <div className="d-none d-lg-block">
        <img src="/images/ib.png" className="w-100" />
      </div>
    </div >
  );
};

export default RightBar;
