import React from "react";
const PrivacyPolicyPage = (props) => {
  return (
    <>
      <div className="container py-4">
        <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '0cm', marginBottom: '8.0pt', lineHeight: '107%'}}>&nbsp;</p>
        <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '0cm', marginBottom: '8.0pt', lineHeight: 'normal', textAlign: 'center'}}><strong><span style={{fontSize: '32px'}}>PRIVACY POLICY</span></strong></p>
        <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: '18.0pt'}}><span style={{fontSize: '16px'}}>This Privacy Policy (<strong>“Policy”</strong>) outlines ADAPAD’s (<strong>“ADAPAD”</strong>, <strong>“we”</strong>, or <strong>“us”</strong>) practices in relation to the storage, use, processing, and disclosure of personal data that you have chosen to share with us when you access the ADAPAD Platform (as defined in the terms) or use the Services (as defined in the Terms). This Policy, together with our Terms, applies to your use of the Platform.</span></p>
        <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: '18.0pt'}}><span style={{fontSize: '16px'}}>At ADAPAD, we are committed to protecting your personal data and respecting your privacy. Please read this Policy carefully to understand our practices regarding your personal data and how we will treat it. The Policy sets out the basis on which any personal data that we collect from you, or that you provide to us, will be processed by us.</span></p>
        <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: '18.0pt'}}><span style={{fontSize: '16px'}}>Unless defined in this Policy, capitalised words shall have the same meaning ascribed to them in our Terms and Conditions, available above&nbsp;(<strong>“Terms”</strong>). Please read this Policy in consonance with our Terms.</span></p>
        <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: '18.0pt'}}><span style={{fontSize: '16px'}}>By accessing the Platform, you consent to the collection, storage, use, and disclosure of your personal data, in accordance with, and are agreeing to be bound by this Policy. We will not collect any information from you, except where it is knowingly and explicitly provided by you.</span></p>
        <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '36.0pt', marginBottom: '12.0pt', lineHeight: 'normal'}}><strong><span style={{fontSize: '24px'}}>1. THE DATA WE COLLECT ABOUT YOU</span></strong></p>
        <ol start={1} style={{marginBottom: '0cm', marginTop: '0cm'}} type="a">
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: '16px'}}>We may collect, use, store, and transfer different kinds of personal data about you in connection with your use of the Services, including but not limited to:</span>
            <ol start={1} style={{marginBottom: '0cm', marginTop: '0cm'}} type="I">
              <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: '16px'}}>transaction data including purchases of crypto assets;</span></li>
              <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: '16px'}}>technical data including IP address, time zone setting and locations, operating system, and other technologies on your device used to access the Platform;</span></li>
            </ol>
          </li>
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: '16px'}}>You do not have to provide any personal data or information to us but in doing so, you may not be able to take advantage of all the Services we offer.</span></li>
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: '16px'}}>Your non-custodial wallet interacts with the App on the Platform, and the smart contract stores your wallet address.</span></li>
        </ol>
        <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '36.0pt', marginBottom: '12.0pt', lineHeight: 'normal'}}><strong><span style={{fontSize: '24px'}}>2. HOW IS YOUR PERSONAL DATA COLLECTED?</span></strong></p>
        <p style={{marginRight: '0cm', marginLeft: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: '18.0pt'}}><span style={{fontSize: '16px'}}>We will collect and process the following data about you:</span></p>
        <ol start={1} style={{marginBottom: '0cm', marginTop: '0cm'}} type="a">
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><u><span style={{fontSize: '16px'}}>Information you give us</span></u><span style={{fontSize: '16px'}}>: This is the information you consent to giving us about you when you use our Services or by corresponding with us (for example, by email or chat). It includes information you provide when you share data through the Platform, through other activities commonly carried out in connection with the Services, and when you report a problem with the Services. If you contact us, we will keep a record of the information shared during the correspondence.</span></li>
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><u><span style={{fontSize: '16px'}}>Information we collect about you and your device</span></u><span style={{fontSize: '16px'}}>: Each time you visit our Platform, or use one of our Services, we will automatically collect personal data including technical and usage data. We may also receive certain usage data, such as your IP address and referral source.</span></li>
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: '16px'}}>We also collect, use, and share aggregated data such as statistical data for any purpose. Aggregated data could be derived from your personal data but is not considered personal data under applicable laws. For example, we may aggregate your usage data to calculate the percentage of users accessing a specific feature of the Services. However, if we combine or connect aggregated data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this Policy.</span></li>
        </ol>
        <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '36.0pt', marginBottom: '12.0pt', lineHeight: 'normal'}}><strong><span style={{fontSize: '24px'}}>3. HOW WE USE YOUR PERSONAL DATA AND FOR WHAT PURPOSES</span></strong></p>
        <p style={{marginRight: '0cm', marginLeft: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: '18.0pt'}}><span style={{fontSize: '16px'}}>We respect data protection principles, and process personal data only for specified, explicit, and legitimate purposes for which such personal data were provided. We primarily use your personal data to enable your use of ADAPAD and supply the Services requested by you. We may also use your personal data for the purposes provided in this Policy, and the purposes listed below:</span></p>
        <ol start={1} style={{marginBottom: '0cm', marginTop: '0cm'}} type="a">
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: '16px'}}>You understand that when you consent to providing us with your personal data, you also consent to us sharing the personal data with third parties, including the government and regulatory entities, should the need arise under law, in connection with or related to the provision of Services.</span></li>
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: '16px'}}>You are aware that any and all information pertaining to you, whether or not you directly provide it to us may be collected, compiled, and shared by us in order to render Services to you and you expressly consent to this.</span></li>
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: '16px'}}>In general, we will not disclose personal data except in accordance with the following purpose or activity:</span>
            <ol start={1} style={{marginBottom: '0cm', marginTop: '0cm'}} type="I">
              <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: '16px'}}>to deliver Services;</span></li>
              <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: '16px'}}>to administer the Services including troubleshooting and system testing;</span></li>
              <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: '16px'}}>to monitor trends so we can improve the Services;</span></li>
              <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: '16px'}}>to perform our obligations that arise out of the arrangement we are about to enter or have entered with you;</span></li>
              <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: '16px'}}>to enforce the terms of the arrangement we have with you or any third party;</span></li>
              <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: '16px'}}>to comply with a legal or regulatory obligation.</span></li>
            </ol>
          </li>
        </ol>
        <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '36.0pt', marginBottom: '12.0pt', lineHeight: 'normal'}}><strong><span style={{fontSize: '24px'}}>4. TRANSFER OF PERSONAL DATA</span></strong></p>
        <ol start={1} style={{marginBottom: '0cm', marginTop: '0cm'}} type="a">
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: '16px'}}>As a part of your use of and to ensure better and seamless delivery of the Services to you, the information and personal data you provide to us may be transferred to and stored at countries other than your home jurisdiction. These countries shall be subject to data laws of their respective countries. We and our service providers may transfer your information to, or store or access it in, jurisdictions that may not provide equivalent levels of data protection as your home jurisdiction. We will take steps to ensure that your personal data receives an adequate level of protection in the jurisdictions in which we process it. By using such Service, you expressly consent to this transfer, and agree and acknowledge that we will not be responsible for any additional terms and conditions, policies, and guidelines.</span></li>
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: '16px'}}>By submitting your information and personal data to us, you agree to the transfer, storage and/or processing of such information, and personal data outside the country you are based in, in the manner described above.</span></li>
        </ol>
        <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '36.0pt', marginBottom: '12.0pt', lineHeight: 'normal'}}><strong><span style={{fontSize: '24px'}}>5. THIRD PARTY SERVICES</span></strong></p>
        <ol start={1} style={{marginBottom: '0cm', marginTop: '0cm'}} type="a">
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: '16px'}}>Our Services may, from time to time, contain services provided by or links to and from the websites of our partner networks, advertisers, and affiliates (“Third Party Services”). Please note that the Third Party Services, that may be accessible through our Services have their own privacy policies. We do not accept any responsibility or liability for the policies or for any personal data that may be collected through the Third Party Services. Please check their policies before you submit any personal data to such websites or use their services.</span></li>
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: '16px'}}>Your relationship with these third parties and their services and tools is independent of your relationship with us. These third parties may allow you to permit/restrict the information that is collected. It may be in your interest to individually restrict or enable such data collections.</span></li>
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: '16px'}}>The place of processing information depends on each third-party service provider and you should check the privacy policy of each of the service providers to identify the data shared and its purpose. You will be subject to a third party’s privacy policy if you opt in to receive communications from third parties. We will not be responsible for the privacy standards and practices of third parties.</span></li>
        </ol>
        <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '36.0pt', marginBottom: '12.0pt', lineHeight: 'normal'}}><strong><span style={{fontSize: '24px'}}>6. DATA SECURITY</span></strong></p>
        <ol start={1} style={{marginBottom: '0cm', marginTop: '0cm'}} type="a">
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: '16px'}}>We implement certain reasonable security measures to protect your personal information from unauthorised access, and such security measures are in compliance with the security practices and procedures as prescribed under the applicable laws. However, you agree and acknowledge that the above-mentioned measures do not guarantee absolute protection to the personal information and by accessing the Services, you agree to assume all risks associated with disclosure of personal information arising due to breach of firewalls and secure server software.</span></li>
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: '16px'}}>We will comply with the requirements of applicable laws in the event of a data or security risk.</span></li>
        </ol>
        <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '36.0pt', marginBottom: '12.0pt', lineHeight: 'normal'}}><strong><span style={{fontSize: '24px'}}>7. DATA RETENTION</span></strong></p>
        <p style={{marginRight: '0cm', marginLeft: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: '18.0pt'}}><span style={{fontSize: '16px'}}>You are aware that your personal data will continue to be stored and retained by us for a reasonable period after your use of the Services.</span></p>
        <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '36.0pt', marginBottom: '12.0pt', lineHeight: 'normal'}}><strong><span style={{fontSize: '24px'}}>8. BUSINESS TRANSITIONS</span></strong></p>
        <p style={{marginRight: '0cm', marginLeft: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '12.0pt', marginBottom: '12.0pt', lineHeight: '18.0pt'}}><span style={{fontSize: '16px'}}>You are aware that in the event we go through a business transition, such as a merger, acquisition by another organisation, or sale of all or a portion of our assets, your personal data might be among the assets transferred.</span></p>
        <p style={{marginRight: '0cm', marginLeft: '0cm', fontSize: '15px', fontFamily: '"Calibri",sans-serif', marginTop: '36.0pt', marginBottom: '12.0pt', lineHeight: 'normal'}}><strong><span style={{fontSize: '24px'}}>9. CHANGE IN PRIVACY POLICY</span></strong></p>
        <ol start={1} style={{marginBottom: '0cm', marginTop: '0cm'}} type="a">
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: '16px'}}>We keep our Policy under regular review and may amend this Policy from time to time, at our sole discretion.</span></li>
          <li style={{marginTop: '12.0pt', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '0cm', lineHeight: '18.0pt', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}><span style={{fontSize: '16px'}}>The terms of this Policy may change and if they do, the changes will be posted on this page and, where appropriate, notified to you on the website. The new Policy may be displayed on-screen and you may be required to read and accept the changes to continue your use of the Services.</span></li>
        </ol>
        </div>
    </>
  );
};

export default PrivacyPolicyPage;
